import utility from '@/common/utility'
import auth from '@/common/auth'

export default class ReportTemplateModel {
  constructor (props) {
    if (props) {
      this.resourceId = props.reportTemplateGuid
      this.reportTemplateGuid = props.reportTemplateGuid
      this.reportTemplateName = props.reportTemplateName
      this.targetUrl = props.targetUrl
      this.staffName = props.staffName
      this.sortId = props.sortId
      this.createUserGuid = props.createUserGuid
      this.createTime = props.createTime
    }
    this.init()
  }

  init () {
    if (!this.resourceId) this.resourceId = ''
    if (!this.reportTemplateGuid) this.reportTemplateGuid = ''
    if (!this.reportTemplateName) this.reportTemplateName = ''
    if (!this.targetUrl) this.targetUrl = ''
    if (!this.staffName) this.staffName = ''
    if (!this.sortId) this.sortId = 0
    if (!this.createUserGuid) this.createUserGuid = auth.getUserInfo().userGuid
    if (!this.createTime) this.createTime = utility.getCurrentTime()
  }

  generatePrimaryKey () {
    this.reportTemplateGuid = utility.getUuid()
    this.resourceId = this.reportTemplateGuid
  }
}
