<template>
  <div class="reportTemplate-index">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            type="text"
            v-model="searchModel.reportTemplateName"
            placeholder="名称"
            clearable>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSearchClick">查询</el-button>
          <el-button type="primary" @click="handleAddClick">添加</el-button>
        </el-form-item>
      </el-form>
    </my-search-bar>
    <my-el-table
      :data="reportTemplateList"
      :total="total"
      :page-index="pageIndex"
      :page-size="pageSize"
      @size-change="handlePageSizeChange"
      @current-change="handlePageIndexChange"
      >
      <el-table-column prop="index" width="50" header-align="center" align="center" label="序号"></el-table-column>
      <el-table-column prop="reportTemplateName" header-align="center" align="center" label="名称"></el-table-column>
      <el-table-column prop="targetUrl" header-align="center" align="center" label="模板地址"></el-table-column>
      <el-table-column prop="staffName" width="80" header-align="center" align="center" label="创建者"></el-table-column>
      <el-table-column prop="sortId" width="50" header-align="center" align="center" label="排序"></el-table-column>
      <el-table-column prop="createTime" :formatter="timeFormatter" width="160" header-align="center" align="center" label="创建时间"></el-table-column>
      <el-table-column width="90" header-align="center" align="center" label="操作">
        <template v-if="!scope.row.empty" slot-scope="scope">
          <el-tooltip class="item" effect="light" content="编辑" :open-delay="500" placement="top">
            <i @click="handleEditClick(scope.row)" class="text-info far fa-edit mr-2 operation-button"></i>
          </el-tooltip>
          <el-tooltip class="item" effect="light" content="删除" :open-delay="500" placement="top">
            <i @click="handleDeleteClick(scope.row)" class="text-danger far fa-trash-alt operation-button"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </my-el-table>

    <my-dialog ref="formDialog"
      :title="formDialogTitle"
      size="md"
      ok-text="保存"
      cancel-text="取消"
      @ok-click="handleOkClick">
      <reportTemplate-form
        ref="reportTemplateForm"
        :reportTemplateModel="reportTemplateModel">
      </reportTemplate-form>
    </my-dialog>
  </div>
</template>

<script>
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import MyDialog from '@/components/MyDialog/MyDialog'
import MyElTable from '@/components/MyElTable/MyElTable'
import ReportTemplateForm from '@/views/ReportTemplateManage/ReportTemplateForm'
import ReportTemplateModel from '@/model/ReportTemplateModel'
import reportTemplateService from '@/services/reportTemplateService'
import PageOperation from '@/mixins/PageOperation'
import PageInfo from '@/support/PageInfo'

export default {
  name: 'ReportTemplateIndex',
  mixins: [
    PageOperation
  ],
  components: {
    MyDialog,
    MySearchBar,
    MyElTable,
    ReportTemplateForm
  },
  data () {
    return {
      total: 0,
      reportTemplateList: [],
      reportTemplateModel: new ReportTemplateModel(),
      formDialogTitle: '',
      searchModel: {
        reportTemplateName: ''
      }
    }
  },
  methods: {
    handleSearchClick () {
      this._getReportTemplateList()
    },
    handleAddClick () {
      this.formDialogTitle = '添加报表模板信息'
      this.reportTemplateModel = new ReportTemplateModel()
      this.$refs.formDialog.open()
    },
    handleEditClick (row) {
      this.formDialogTitle = '编辑报表模板信息'
      this.reportTemplateModel = new ReportTemplateModel(row)
      this.$refs.formDialog.open()
    },
    handleDeleteClick (row) {
      this.$confirm('您确定要删除吗？')
        .then(() => {
          let _reportTemplateModel = new ReportTemplateModel(row)
          reportTemplateService.delete(_reportTemplateModel.resourceId)
            .then(res => {
              window.console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getReportTemplateList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              window.console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    handleOkClick () {
      this.$refs.reportTemplateForm.validate()(valid => {
        if (valid) {
          if (!this.reportTemplateModel.resourceId) {
            this._save()
          } else {
            this._edit()
          }
        } else {
          this.$message({
            type: 'error',
            message: '请检查您的输入！'
          })
        }
      })
    },
    _getReportTemplateList () {
      reportTemplateService.list(new PageInfo({ pageSize: this.pageSize, pageIndex: this.pageIndex, fieldOrder: 'reportTemplates.sortId' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.total = res.data.pageCount
            this.reportTemplateList = res.data.data
            this.$message({
              type: 'success',
              message: '列表加载成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          window.console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    _save () {
      reportTemplateService.add(this.reportTemplateModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getReportTemplateList()
            this.$message({
              type: 'success',
              message: '添加成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '添加失败！'
          })
          window.console.log(err.request.response)
        })
    },
    _edit () {
      reportTemplateService.edit(this.reportTemplateModel)
        .then(res => {
          window.console.log(res)
          if (res.data.code === 1) {
            this.$refs.formDialog.close()
            this._getReportTemplateList()
            this.$message({
              type: 'success',
              message: '修改成功！'
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          this.$message({
            type: 'error',
            message: '修改失败！'
          })
          window.console.log(err.request.response)
        })
    }
  },
  created () {
    this._getReportTemplateList()
  }
}
</script>
