import BaseService from '@/services/BaseService'

let resourceName = 'reportTemplate'

class ReportTemplateService extends BaseService {
}

const reportTemplateService = new ReportTemplateService(resourceName)

export default reportTemplateService
