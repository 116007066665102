<template>
  <el-form
    ref="elForm"
    :rules="rules"
    :model="reportTemplateModel"
    label-width="100px"
    >
    <el-form-item label="名称：" prop="reportTemplateName">
      <el-input
        type="text"
        v-model="reportTemplateModel.reportTemplateName">
      </el-input>
    </el-form-item>
    <el-form-item label="模板地址：" prop="targetUrl">
      <el-input
        type="text"
        v-model="reportTemplateModel.targetUrl">
      </el-input>
    </el-form-item>
    <el-form-item label="排序：" prop="sortId">
      <el-input-number
        v-model="reportTemplateModel.sortId"
        :step="1"
        :min="0"
        :precision="0"
        step-strictly>
      </el-input-number>
    </el-form-item>
  </el-form>
</template>

<script>
import ReportTemplateModel from '@/model/ReportTemplateModel'

export default {
  name: 'ReportTemplateForm',
  props: {
    reportTemplateModel: {
      type: ReportTemplateModel,
      default () {
        return new ReportTemplateModel()
      }
    }
  },
  data () {
    return {
      rules: {
        reportTemplateName: [
          {
            required: true,
            message: '请输入名称',
            trigger: 'blur'
          }
        ],
        targetUrl: [
          {
            required: true,
            message: '请输入模板地址',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  methods: {
    validate () {
      return this.$refs.elForm.validate
    }
  }
}
</script>
